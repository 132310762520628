export const TwitterLinkHandler = (url, internal, type) => {
  const twitterHandler = /^@/g
  if (type === "twitter_url" && twitterHandler.test(url)) {
    return "https://twitter.com/" + url
  }

  let url_mod = url.trim()
  // const intern = /^\/(?!\/)/.test(url)
  const extern = /^(https?:\/\/)/.test(url_mod)

  if ( internal !== true && extern !== true && type === "email" ) {
    return "mailto:" + url_mod
  } else if ( internal !== true && extern !== true ) {
    return "https://" + url_mod
  } else {
    return url_mod
  }

}

export const HandleDisplayLinks = (url) => {
  const extern = /^(https?:\/\/)/
  const trailingslash = /\/$/

  return url.trim().replace(extern, "").replace(trailingslash, "")

}