export const sortProgram = (p, devider) => {
  let e;
  let numberOnly = /\d+/
  let output = [];

  if (typeof(p) === "object") {
    e = p
  } else {
    e = p
      .split(devider)
      .map(Function.prototype.call, String.prototype.trim)
  }

  if ( e.length > 0) {
    let numbers = e.filter(el => el.match(numberOnly));
    let other = e.filter(el => numbers.indexOf(el) < 0);
    let temp = [];

    numbers.sort((a, b) => parseFloat(a.match(numberOnly)[0]) - parseFloat(b.match(numberOnly)[0]))
    other.sort()

    temp.push(numbers)
    temp.push(other)

    temp.forEach(el => {
      el.forEach(a => {
        output.push(a)
      })
    })
  }

  return output;
}